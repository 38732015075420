import axios, { AxiosResponse } from "axios";
import ENV_CONFIG from "../config";
import { getAuthHeaders } from "../common";
import { IAcnowledgementResponse, ICreateDwTokenResponse, ICreateSharingResponse, IDwToken, IExpireDwTokenResponse, ILicense, IPagedResult, IPasswordReset, ISharingRequest, ITransaction, ITransactionOwnersResponse } from "./models";

class InfraMarkerService {
   resetUrl = ENV_CONFIG.BASE_API_URL + '/api/v1/password-reset/';
   dwTokenUrl = ENV_CONFIG.BASE_API_URL + '/api/v1/dw-token/'
   dwSharingUrl = ENV_CONFIG.BASE_API_URL + '/api/v1/dw-sharing/'

   generateDwToken(orgId: string, name: string, expiration: Date, geofilter: File | null, authToken: string, ownerFilter: string[], assetFilterType: string, assetFilterValue: string): Promise<AxiosResponse<ICreateDwTokenResponse>> {
      let formData = new FormData();
      formData.append("name", name);
      formData.append("org_id", orgId);
      formData.append("expiration_date", expiration.toISOString().split('T')[0]);
      if (geofilter !== null) {
         formData.append("geofilter", geofilter);
      }
      
      ownerFilter.forEach( o => formData.append("owner_filter", o));
      if (assetFilterValue) {
         formData.append("asset_filter_type", assetFilterType);
         formData.append("asset_filter_value", assetFilterValue);
      }

      return axios.post(
         this.dwTokenUrl, 
         formData,
         getAuthHeaders(authToken));
   }

   getDwTokens(authToken: string): Promise<AxiosResponse<IPagedResult<IDwToken>>> {
      return axios.get(this.dwTokenUrl, getAuthHeaders(authToken));
   }

   getDwTokenPage(pagedUrl: string, authToken: string): Promise<AxiosResponse<IPagedResult<IDwToken>>> {
      return axios.get(pagedUrl, getAuthHeaders(authToken));
   }

   expireDwToken(dwToken: string, authToken: string): Promise<AxiosResponse<IExpireDwTokenResponse>>  {
      return axios.put(this.dwTokenUrl + dwToken + '/', {}, getAuthHeaders(authToken));
   }

   createDataShare(dwToken: string, description: string, recipient: string, url: string, authToken: string): Promise<AxiosResponse<ICreateSharingResponse>> {
      let payload = { token: dwToken, description, recipient, url };
      return axios.post(this.dwSharingUrl, payload, getAuthHeaders(authToken));
   }

   getDataShares(authToken: string, url: string = this.dwSharingUrl): Promise<AxiosResponse<IPagedResult<ISharingRequest>>> {
      // Get data shares, default to sharing URL but allow overriding to support paging      
      return axios.get(url, getAuthHeaders(authToken));
   }

   expireDataShare(id: string, authToken: string) {
      return axios.put(this.dwSharingUrl + id + '/', {}, getAuthHeaders(authToken));
   } 

   resendDataShare(id: string, authToken: string) {
      return axios.post(this.dwSharingUrl, { id }, getAuthHeaders(authToken));
   }

   acknowledgeDataShare(id: string): Promise<AxiosResponse<IAcnowledgementResponse>>  {
      return axios.put(ENV_CONFIG.BASE_API_URL + '/api/v1/dw-sharing-acknowledge/', {id});
   }

   getTransactionOwners(org_id: string, authToken: string): Promise<AxiosResponse<ITransactionOwnersResponse>> {
      return axios.get(ENV_CONFIG.BASE_API_URL + '/api/v1/transaction-owners/?org_id=' + org_id, getAuthHeaders(authToken));
   }

   getLicense(orgId: string, authToken: string): Promise<AxiosResponse<ILicense>> {
      return axios.get(ENV_CONFIG.BASE_API_URL + '/api/v1/license/?org_id=' + orgId, getAuthHeaders(authToken));
   }

   getTransactionData(getTransactionUrl: string, authToken: string): Promise<AxiosResponse<IPagedResult<ITransaction>>> {
      return axios.get(getTransactionUrl, getAuthHeaders(authToken));
   }

   getTransactionExport(getTransactionExportUrl: string, authToken: string): Promise<AxiosResponse> {
      return axios.get(getTransactionExportUrl, getAuthHeaders(authToken));
   }

   submitPasswordResetRequest(email?: string, authToken?: string): Promise<AxiosResponse<IPasswordReset>> {
      if (email) {
         return axios.post(this.resetUrl, {email});
      } else {
         return axios.post(this.resetUrl, {},  getAuthHeaders(authToken));
      }
   }

   setPassword(password: string, token: string, userId?: string, email?: string, authToken?: string): Promise<AxiosResponse> {
      if (!userId && !email && !authToken) {
         throw new Error("You must either specify userId or email or have a token from an authenticated user to set the password.");
      }

      if (userId) {
         return axios.put(this.resetUrl,  { pwd: password, token: token, user_id: userId })
      } else if (email) {
         return axios.put(this.resetUrl,  { pwd: password, token: token, email: email })
      } else {
         return axios.put(this.resetUrl,  { pwd: password, token: token }, getAuthHeaders(authToken))
      }
   }

   submitContactUs(email: string, username: string, authToken: string | null, supportText: string) {
      const supportUrl = ENV_CONFIG.BASE_API_URL + '/api/v1/support/';

      if (authToken) {
         return axios.post(supportUrl, { support_text: supportText },  getAuthHeaders(authToken));
      } else {
         return axios.post(supportUrl, { email, username, support_text: supportText });
      }
   }
}

let inframarkerService = new InfraMarkerService();
export default inframarkerService