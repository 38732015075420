import React, { useEffect, useState } from 'react';
import Organization from './Organization';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import Loader from '../common/Loader';

function Dashboard ({ auth, orgs }) {

   const [organizations, setOrganizations] = useState([]);
   const [isLoaded, setIsLoaded] = useState(false);
   const navigate = useNavigate();

   useEffect(() => {
      if (orgs !== null) {
         setIsLoaded(true);
         setOrganizations(orgs);
      }
   }, [auth.access_token, orgs]);

   function navigateToChangePassword() {
      navigate('/change-password');
   }

   const inactiveOrgMessage = 'The logged in user is not associated to an active organization.'

   const noOrgErrorMessage = 'The logged in user is not associated to an organization.'

   const pleaseCallMessage = 'Please call us at 877-686-8550 between the hours of 8:00 AM and 4:30 PM Monday through Friday ' +
      '(Central Time) or email us at info@inframarker.com for assistance.'

   return (
      (!isLoaded) ? <Loader message='Loading...' /> :
      <div className='main-content'>
         {
            (auth.user?.force_portal_password_reset) ?
               <div>
                  <h3>You are required to change your password in order to access this site.  You will be sent a password reset email and are being redirected to the reset screen.</h3>
                  <button className="btn btn-primary" onClick={navigateToChangePassword}>
                     Ok
                  </button>
               </div> :
               (!organizations.length) ?
                  <p>{noOrgErrorMessage}<br /><br />{pleaseCallMessage}</p>
                  :
                  !organizations.some(o => o.is_active) ?
                     <p>{inactiveOrgMessage}<br /><br />{pleaseCallMessage}</p>
                     :
                     organizations.filter(o => o.is_active).map(
                        org => <Organization name={org.name} key={org.id} id={org.id} owner={org.asset_owner} has_data_sharing={org.allow_datasharing} />
                     )
                  }
      </div>
   );
}

const mapStateToProps = (state) => ({
   auth: state.auth,
   orgs: state.org.orgs
})
export default connect(mapStateToProps)(Dashboard);

